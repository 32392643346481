<template>
    <main class="page">
        <section class="hero">
            <div class="hero__container-banner">
                <div class="hero__body">
                    <swiper :modules="modules" :observer="true" :observeParents="true" :speed="800" :slides-per-view="1" :pagination="{ clickable: true }" :autoplay="{ delay: 3000 }" class="hero__slider">
                        <swiper-slide v-for="(banner, index) in banners.banners" :key="index" class="hero__slide-ibg">
                            <router-link :to="{ name: 'Catalog', params: { categoryId: banner.url } }">
                                <img :src="'https://img.le-perle.com.ua/image-resizing?width=1270&image=' + $uploadUrl + banner.downloads[0].path" alt="banner_2_17_2_24.webp" class="desktop-only" />
                                <img :src="'https://img.le-perle.com.ua/image-resizing?width=600&image=' + $uploadUrl + banner.downloads[1].path" alt="banner_2_17_2_24_mob.webp" class="mobile-only" />
                            </router-link>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="hero__container">
                <swiper
                    :modules="modules"
                    :observer="true"
                    :observeParents="true"
                    :speed="800"
                    :navigation="{
                        prevEl: '.hero__navigation .btn-prev',
                        nextEl: '.hero__navigation .btn-next',
                    }"
                    :breakpoints="{
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        767: {
                            slidesPerView: 3,
                            spaceBetween: 25,
                        },
                        991: {
                            slidesPerView: 4,
                            spaceBetween: 25,
                        },
                        1270: {
                            slidesPerView: 5,
                            spaceBetween: 48,
                        },
                    }"
                    class="hero__items"
                >
                    <swiper-slide class="hero__item">Прикраси з Високоякісних камінців та перлин</swiper-slide>
                    <swiper-slide class="hero__item">Ручна робота та власне виробництво</swiper-slide>
                    <swiper-slide class="hero__item">Унікальні та актуальні моделі прикрас</swiper-slide>
                    <swiper-slide class="hero__item">Люксова фурнітура високої якості</swiper-slide>
                    <swiper-slide class="hero__item">Великий асортимент та вибір прикрас</swiper-slide>
                </swiper>
                <div class="hero__navigation navigation">
                    <button class="navigation__btn btn-prev"></button>
                    <button class="navigation__btn btn-next"></button>
                </div>
            </div>
        </section>

        <ProductSlider :title="'New Collection'" :slug="'new-collection'" :categoryId="1" :id="1" />

        <section class="types types_mob">
            <div class="types__container">
                <div class="types__items">
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'kolye' } }" class="types__item-ibg">
                        <img src="../assets/img/kolye.webp" alt="Кольє" style="object-position: top" />
                        <div class="types__text _icon-arrow-right">Кольє</div>
                    </router-link>
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'kabluchki' } }" class="types__item-ibg">
                        <img src="../assets/img/kabluchko.webp" alt="Каблучки" />
                        <div class="types__text _icon-arrow-right">Каблучки</div>
                    </router-link>
                </div>
            </div>
        </section>

        <section class="types types_pc">
            <div class="types__container">
                <div class="types__items">
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'kolye' } }" class="types__item-ibg types__item-ibg_big">
                        <img src="../assets/img/kolye.webp" alt="Кольє" />
                        <div class="types__text _icon-arrow-right">Кольє</div>
                    </router-link>

                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'serezhki' } }" class="types__item-ibg">
                        <img src="../assets/img/serejki.webp" alt="Сережки" />
                        <div class="types__text types__text_right _icon-arrow-right">Сережки</div>
                    </router-link>
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'kabluchki' } }" class="types__item-ibg">
                        <img src="../assets/img/kabluchko.webp" alt="Каблучки" style="object-position: 0 -220px" />
                        <div class="types__text _icon-arrow-right">Каблучки</div>
                    </router-link>
                </div>
            </div>
        </section>

        <ProductSlider :title="'Sale'" :slug="'sale'" :id="2" />

        <section class="types types_mob">
            <div class="types__container">
                <div class="types__items">
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'serezhki' } }" class="types__item-ibg">
                        <img src="../assets/img/serejki.webp" alt="Сережки" />
                        <div class="types__text types__text_left _icon-arrow-right">Сережки</div>
                    </router-link>
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'brasleti' } }" class="types__item-ibg">
                        <img src="../assets/img/brasleti.webp" alt="Браслети" />
                        <div class="types__text _icon-arrow-right">Браслети</div>
                    </router-link>
                </div>
            </div>
        </section>

        <section class="types types_pc">
            <div class="types__container">
                <div class="types__items">
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'seti-prikras' } }" class="types__item-ibg types__item-ibg_big">
                        <img src="../assets/img/seti.webp" alt="Сети прикрас" />
                        <div class="types__text types__text_left _icon-arrow-right">Сети прикрас</div>
                        <!-- <div class="types__text types__text_left _icon-arrow-right">Сети прикрас</div> -->
                    </router-link>
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'ankleti' } }" class="types__item-ibg">
                        <img src="../assets/img/ankleti.webp" alt="Анклети" />
                        <div class="types__text _icon-arrow-right">Анклети</div>
                    </router-link>
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'brasleti' } }" class="types__item-ibg">
                        <img src="../assets/img/brasleti.webp" alt="Браслети" />
                        <div class="types__text _icon-arrow-right">Браслети</div>
                    </router-link>
                </div>
            </div>
        </section>

        <ProductItems :title="'Каблучки'" :slug="'kabluchki'" :products="produtsHeels" :id="3" :category_id="'kabluchki'" />

        <section class="types types_mob">
            <div class="types__container">
                <div class="types__items">
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'ankleti' } }" class="types__item-ibg">
                        <img src="../assets/img/ankleti.webp" alt="Анклети" />
                        <div class="types__text _icon-arrow-right">Анклети</div>
                    </router-link>
                    <router-link :to="{ name: 'Catalog', params: { categoryId: 'seti-prikras' } }" class="types__item-ibg">
                        <img src="../assets/img/seti.webp" alt="Сети прикрас" />
                        <div class="types__text types__text_left _icon-arrow-right">Сети прикрас</div>
                    </router-link>
                </div>
            </div>
        </section>

        <section class="reviews">
            <div class="reviews__container">
                <div class="reviews__top top-block">
                    <h2 class="top-block__title">Відгуки від наших перлинок</h2>
                    <div class="reviews__navigation navigation navigation_slider">
                        <button class="navigation__btn btn-prev"></button>
                        <button class="navigation__btn btn-next"></button>
                    </div>
                </div>
                <swiper
                    class="reviews-slider"
                    :modules="modules"
                    :observer="true"
                    :observeParents="true"
                    :speed="300"
                    :autoplay="{ delay: 3000 }"
                    :scrollbar="{
                        draggable: true,
                    }"
                    :mousewheel="{
                        forceToAxis: true,
                    }"
                    :navigation="{
                        prevEl: '.reviews__navigation .btn-prev',
                        nextEl: '.reviews__navigation .btn-next',
                    }"
                    :breakpoints="swiperReviews.breakpoints"
                >
                    <swiper-slide>
                        <img src="../assets/img/reviews/0001.webp" alt="0001.webp" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../assets/img/reviews/0002.webp" alt="0002.webp" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../assets/img/reviews/0003.webp" alt="0003.webp" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../assets/img/reviews/0004.webp" alt="0004.webp" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../assets/img/reviews/0005.webp" alt="0005.webp" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../assets/img/reviews/0006.webp" alt="0006.webp" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../assets/img/reviews/0007.webp" alt="0007.webp" />
                    </swiper-slide>
                </swiper>
            </div>
        </section>

        <ProductItems :title="'Сережки'" :products="produtsEarrings" :id="4" :category_id="'sale'" />

        <section class="links">
            <div class="links__container">
                <div class="links__top top-block">
                    <h2 class="top-block__title links__title">Переходь до нас в інстаграм, щоб першою дізнаватись про новинки</h2>
                </div>
            </div>
            <div class="links__body">
                <div class="links__item-ibg"><img src="../assets/img/links/01.webp" alt="01.webp" /></div>
                <div class="links__item-ibg"><img src="../assets/img/links/02.webp" alt="02.webp" /></div>
                <div class="links__item-ibg"><img src="../assets/img/links/03.webp" alt="03.webp" /></div>
                <div class="links__item-ibg"><img src="../assets/img/links/04.webp" alt="04.webp" /></div>
                <div class="links__item-ibg links__item-ibg_hide"><img src="../assets/img/links/05.webp" alt="05.webp" /></div>
            </div>
            <div class="links__container">
                <a href="https://instagram.com/le_perle.ua?igshid=MzRlODBiNWFlZA==" class="links__link" target="_blank">@le_perle.ua</a>
            </div>
        </section>

        <ProductItems :title="'Анклети'" :products="produtsQuestionnaires" :id="5" :category_id="'ankleti'" />

        <section class="about">
            <div class="about__container">
                <div class="about__top top-block">
                    <h2 class="top-block__title">Про наш бренд</h2>
                </div>
                <div class="about__body">
                    <div class="about__text">
                        <p>Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей.</p>
                        <p>Наша ідея полягає в виготовленні елегантних прикрас з використанням перлів та чудового каміння, що символізують внутрішню силу і блиск душі. Кожен наш виріб втілення унікальної історії, надихнутої силами, які приносять надію та ємність до життя.</p>
                        <p>Ми постійно оновлюємо наш асортимент, створюємо нові шедеври та даруємо незрівнянні емоції наши красуням. А також ми виготовляємо прикраси на замовлення по вашим фото та бажанням - просто пиши нам, а ми відтворимо усі твої запити.</p>
                        <p>Le'Perle - це світлова точка у темних часах, нагадуючи про непохитну красу, що приходить із внутрішнього сяйва кожної жінки!</p>
                    </div>
                    <div class="about__image-ibg"><img src="../assets/img/about/01.webp" alt="01.webp" /></div>
                </div>
            </div>
        </section>

        <!--
  <section class="page">
    <div class="page__container">
      <div class="page__body"></div>
    </div>
  </section> -->
    </main>
</template>

<script>
import { Pagination, Autoplay, Navigation, Scrollbar, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import ProductSlider from "./ProductSlider.vue";
import ProductItems from "./ProductItems.vue";
import { useHead } from "@vueuse/head";

import "swiper/css";
import "swiper/css/mousewheel";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ProductSlider,
        ProductItems,
    },
    data() {
        return {
            swiperReviews: {
                breakpoints: {
                    320: {
                        slidesPerView: 2.2,
                        spaceBetween: 15,
                    },
                    767: {
                        slidesPerView: 2.5,
                        spaceBetween: 25,
                    },
                    991: {
                        slidesPerView: 3,
                        spaceBetween: 35,
                    },
                    1270: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                },
            },
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            productsNewCollection: [],
            produtsSale: [],
            produtsHeels: [],
            produtsEarrings: [],
            produtsQuestionnaires: [],
            banners: [],
        };
    },
    setup() {
        useHead({
            title: "Le-perle",
            meta: [
                {
                    name: "description",
                    content: "Le'Perle - унікальний український бренд прикрас, що народився під час війни, має місію надихати красою і надією. Спеціалізуючись на виготовленні елегантних прикрас з перлів та чудового каміння, ми символізуємо внутрішню силу і сяйво душі. Кожен наш виріб - це унікальна історія, втілена в розкішних прикрасах, що пробуджують надію та любов до життя. Відкрийте для себе наш постійно оновлюваний асортимент і персоналізовані прикраси, створені за вашими індивідуальними бажаннями. Le'Perle - символ вічної краси та світла у складний час.",
                },
                {
                    property: "og:title",
                    content: "Le-perle – Прикраси з Високоякісних камінців та перлин",
                },
                {
                    property: "og:description",
                    content: "Le'Perle - унікальний український бренд прикрас, що народився під час війни, має місію надихати красою і надією. Спеціалізуючись на виготовленні елегантних прикрас з перлів та чудового каміння, ми символізуємо внутрішню силу і сяйво душі. Кожен наш виріб - це унікальна історія, втілена в розкішних прикрасах, що пробуджують надію та любов до життя. Відкрийте для себе наш постійно оновлюваний асортимент і персоналізовані прикраси, створені за вашими індивідуальними бажаннями. Le'Perle - символ вічної краси та світла у складний час.",
                },
                {
                    property: "og:image",
                    content: "https://ig.le-perle.com.ua/uploads/logo-og-image.jpg", // URL изображения
                },
                {
                    property: "og:url",
                    content: "https://le-perle.com.ua/", // URL страницы
                },
                {
                    property: "og:type",
                    content: "website",
                },
            ],
        });

        return {
            modules: [Pagination, Autoplay, Navigation, Scrollbar, Mousewheel],
        };
    },
    methods: {
        async getProductsById(id) {
            try {
                const res = await this.axios.get(this.apiUrl + "home/products/" + id, {});
                return res.data.data;
            } catch (error) {
                console.error(error);
            }
        },

        async getProductByCategory() {
            this.produtsHeels = await this.getProductsById(3);
            this.produtsEarrings = await this.getProductsById(4);
            this.produtsQuestionnaires = await this.getProductsById(5);
        },

        async getBanners() {
            try {
                const response = await this.axios.get(this.apiUrl + "home");
                if (response) {
                    this.banners = response.data;
                }
            } catch (error) {
                console.error(error);
            }
        },
    },

    mounted() {
        this.getProductByCategory();
        this.getBanners();
    },
};
</script>

<style>
/* Это будет отображаться только на десктопе */
.desktop-only {
    display: block;
}

/* Это будет отображаться только на мобильных устройствах */
.mobile-only {
    display: none;
}

/* Медиа-запрос для мобильных устройств */
@media screen and (max-width: 768px) {
    .desktop-only {
        display: none;
    }
    .mobile-only {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 50vh;
    }
    .hero__body .swiper-slide {
        height: 0;
    }
}
</style>
