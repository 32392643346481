<template>
    <div class="popup popup-cart" :class="{ 'popup-show': show }">
        <div class="popup__wrapper">
            <div class="popup__content">
                <div class="cart">
                    <div class="cart__top-wrapper">
                        <div class="cart__top top-cart">
                            <div class="top-cart__title">Кошик</div>
                            <button type="button" class="popup__close _icon-close" @click="closePopup"></button>
                        </div>
                        <div class="cart__progresses">
                            <div class="cart__progress-block">
                                <div class="cart__progress"><span :style="{ width: getProgressWidth() }"></span></div>
                                <!-- <span>До безкоштовної доставки залишилось:</span> -->
                                <span>Безкоштовна доставка на повну оплату 🖤</span>
                                <!-- <span>{{ getRemainingAmount() }} грн.</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="cart__items-wrapper">
                        <CartItems />
                    </div>
                    <div class="cart__promocode">
                        <div class="cart__promocode-title cart__promocode-smaller" @click="toggleIsVisiblePromocode"><u>Маєш промокод?</u></div>
                        <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                            <div class="cart__promocode" v-show="isVisiblePromocode">
                                <PromocodeComponent />
                            </div>
                        </transition>
                    </div>
                    <div class="cart__order-bottom">
                        <div class="cart__order order-cart">
                            <div class="order-cart__title">Ваше замовлення</div>
                            <div class="order-cart__line">
                                <span>Сума замовлення:</span>
                                <span>{{ totalOldPriceCart }} грн.</span>
                            </div>
                            <div class="order-cart__line">
                                <span>Знижка:</span>
                                <span>{{ totalDiscountAmount }} грн.</span>
                            </div>
                            <div class="order-cart__all">
                                <span>Разом:</span>
                                <span>{{ totalPriceCart }} грн.</span>
                            </div>
                        </div>
                        <div class="order-cart__buttons">
                            <a href="#" @click="closePopup" class="order-cart__button button button_line order-cart__next"> Продовжити покупки </a>
                            <router-link to="/order" @click="closePopup" class="order-cart__button button"> Перейти до оплати </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex";
import CartItems from "./CartItems.vue";
import PromocodeComponent from "./PromocodeComponent";
import { ref, computed } from "vue";

export default {
    name: "CartPopup",
    components: {
        CartItems,
        PromocodeComponent,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const store = useStore();
        const cart = ref([]);
        const freeDeliveryThreshold = 0;
        const totalPriceCart = computed(() => store.getters["totalPriceCart"]);
        const totalOldPriceCart = computed(() => store.getters["totalOldPriceCart"]);
        const totalDiscountAmount = computed(() => store.getters["totalDiscountAmount"]);

        const priceTotalActionOneToOne = ref(0);

        const getProgressWidth = () => {
            const total = totalPriceCart.value;
            return total >= freeDeliveryThreshold ? "100%" : `${(total / freeDeliveryThreshold) * 100}%`;
        };

        const getProgressWidthAmoutForFreeGift = () => {
            const total = totalPriceCart.value;
            return total >= 1000 ? "100%" : `${(total / 1000) * 100}%`;
        };

        const getRemainingAmount = () => {
            const total = totalPriceCart.value;
            return total >= freeDeliveryThreshold ? 0 : Math.round(freeDeliveryThreshold - total);
        };

        const filterOptionValues = (optionValues) => {
            if (optionValues && Array.isArray(optionValues)) {
                return optionValues.filter((option) => option && option.value !== null && option.value !== undefined);
            }
            return []; // Возвращаем пустой массив, если optionValues undefined или пуст
        };

        return {
            cart,
            filterOptionValues,
            getProgressWidth,
            getRemainingAmount,
            priceTotalActionOneToOne,
            getProgressWidthAmoutForFreeGift,
            totalPriceCart,
            totalOldPriceCart,
            totalDiscountAmount,
        };
    },

    methods: {
        closePopup() {
            this.$store.commit("closeCart");
        },
        toggleIsVisiblePromocode() {
            this.isVisiblePromocode = !this.isVisiblePromocode;
        },
        beforeEnter(el) {
            el.style.maxHeight = "0";
        },
        enter(el) {
            el.style.maxHeight = `${el.scrollHeight}px`;
            el.style.overflow = "unset";
        },
        beforeLeave(el) {
            el.style.maxHeight = `${el.scrollHeight}px`;
            el.style.overflow = "unset";
        },
        leave(el) {
            el.style.maxHeight = "0";
            el.style.overflow = "hidden";
        },
    },
    data() {
        return {
            isVisiblePromocode: true,
        };
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.cart-item-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.cart__progresses {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;
}

.cart__progress-block {
    margin-bottom: 0;
}

.cart-progress-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 2;
}

.item-cart__discount {
    color: #ff0000;
    font-size: 12px;
    font-weight: 700;
    margin-left: 10px;
}

.cart__promocode {
    margin-top: 20px;
}

.cart__promocode-title {
    cursor: pointer;
    margin-bottom: 10px;
    font-size: rem(12);
}

.order-cart__next {
    // background-color: #4ea525;
    border-color: #4ea525;
    // color: #fff;
}
</style>
